import { isPicture, isObjFile, isGlTFFile } from "../common/CheckFileType"
import { StorageKeys } from "../common/AppMode"
import { HMFileIO } from "../io/HMFileIO"
import { VIEWER_URL, VIEWER_DEV_URL } from "../io/UrlConfig"
import { flattenGeomComposedImage } from "../AppTools"

const DEBUG = false

const b64ToUint8Array = (b64Image) => {
  var img = atob(b64Image.split(",")[1])
  var img_buffer = []
  var i = 0
  while (i < img.length) {
    img_buffer.push(img.charCodeAt(i))
    i++
  }
  return new Uint8Array(img_buffer)
}

const uploadUri = async (fblob, folderId, hide, overwrite) => {
  const metadata = hide ? { tags: ["hide"] } : {}
  return HMFileIO()
    .uploadFileXHR(fblob, folderId, metadata, overwrite)
    .then((res) => {
      if (DEBUG) console.log("Uploaded slide file to FileManager : ", res)
      return res
    })
}

const openViewer = (urlArgs) => {
  if (urlArgs.includes("mobileViewer=APP_ANDROID_UNITY")) {
      // launch unity app on device
      window.open("http://studio.holomake.fr/unityviewer?" + VIEWER_URL + urlArgs, "HoloMake|Overlay")
  } else if (urlArgs.includes("mobileViewer=APP_ANDROID")) {
      // launch unity app on device
      if (urlArgs.includes("branch=dev")) {
          window.open("http://studio.holomake.fr/mobileViewerAppAndroid?" + VIEWER_DEV_URL + urlArgs, "HoloMake|Overlay (dev)")
      } else {
          window.open("http://studio.holomake.fr/mobileViewerAppAndroid?" + VIEWER_URL + urlArgs, "HoloMake|Overlay")
      }
  } else if (urlArgs.includes("branch=dev")) {
      // open a new web tab in browser
      window.open(VIEWER_DEV_URL + urlArgs, "HoloMake|Overlay (dev)")
  } else {
      // open a new web tab in browser
      window.open(VIEWER_URL + urlArgs, "HoloMake|Overlay")
  }
}

const launchViewer = async (item, deviceId, deviceList, rootDirId) => {
  // if item is a geom composed picture then flatten it
  const win = window.URL || window.webkitURL || window
  console.log("Display viewer on ", item)
  if (isPicture(item.src) && item.metadata.geom_compo_id) {
    console.log("Flatten composed picture file...")
    let file_uri = await flattenGeomComposedImage(item)
    let filenames = item.src.split(".")
    let format = filenames.pop()
    format = "png"
    let filename = `${filenames.join(".")}_flatten.${format}`
    const file = new File([b64ToUint8Array(file_uri)], filename, {
      type: "image/png",
    })
    win.revokeObjectURL(file_uri)
    const response = await uploadUri(file, item.parent, true, true)
    console.log("FLATTEN FILE UPLOADED TO : ", response)
    // replace original item by new flatten image
    item = response
  }
  // retrieve copy link content if any
  let link = sessionStorage.getItem(StorageKeys().CLIPBOARD_LINK_KEY)
  if (link && link.startsWith("http"))
    link = link.slice(link.lastIndexOf("#") + 1)
  const deviceIdArg =
    deviceId && deviceId !== "None" ? "&deviceId=" + deviceId : ""
  // some default viewer settings
  let linkToken = ""
  let poseDrawArg = "&poseDraw=false"
  let captureArg = "&noCapture=true"
  let cameraRotateArg = ""
  let cameraRealsizeArg = ""
  let keyInputModeArg = "&keyInputMode=100"
  let optionalArgs = ""
  if (link) linkToken = "&linkToken=" + link
  if (deviceId && deviceId !== "None") {
    const dev = deviceList.find((dev) => dev.key === deviceId)
    if (dev.type === "MONITOR") {
      cameraRealsizeArg = "&realSize=true"
    } else if (dev.type === "CAMERA") {
      poseDrawArg = "&poseDraw=true"
      captureArg = "&captureDeviceIndex=" + dev.capture_device_index
      cameraRotateArg = dev.camera_rotate_180
        ? "&videoRotate180=true"
        : "&videoRotate180=false"
      cameraRealsizeArg = dev.camera_realsize
        ? "&realSize=true"
        : "&realSize=false"
      keyInputModeArg = "&keyInputMode=200"
    } else if (dev.type === "PROJECTOR") {
      poseDrawArg = "&poseDraw=true"
      keyInputModeArg = "&keyInputMode=300"
    }
    if (dev.viewer_args) {
      optionalArgs = dev.viewer_args.startsWith("&")
        ? dev.viewer_args
        : "&" + dev.viewer_args
    }
  }
  let args_url =
    "?noSplashImage=true" +
    poseDrawArg +
    cameraRotateArg +
    cameraRealsizeArg +
    keyInputModeArg +
    captureArg +
    deviceIdArg +
    "&token=" +
    item.id +
    "&folderId=" +
    item.parent +
    "&projectFolderId=" +
    rootDirId +
    linkToken +
    optionalArgs
  if (isObjFile(item.src) || isGlTFFile(item.src)) args_url += "&webGLView=true"
  openViewer(args_url)
}

export { launchViewer }
